import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white shadow-lg rounded-lg px-4 py-3 text-neutral-dark w-full h-80" }
const _hoisted_2 = { class: "font-bold text-lg" }
const _hoisted_3 = { class: "flex flex-col pt-3 pb-3" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h5", _hoisted_2, _toDisplayString($setup.SYSTEM.company_information), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, [
        _createVNode($setup["ReadOnlyField"], {
          label: $setup.SYSTEM.country,
          content: $props.companyInformation?.Country!
        }, null, 8, ["label", "content"])
      ]),
      _createElementVNode("div", null, [
        _createVNode($setup["ReadOnlyField"], {
          label: $setup.SYSTEM.province,
          content: $props.companyInformation?.Province!
        }, null, 8, ["label", "content"])
      ]),
      _createElementVNode("div", null, [
        _createVNode($setup["ReadOnlyField"], {
          label: $setup.SYSTEM.company_name,
          content: $props.companyInformation?.CompanyName!
        }, null, 8, ["label", "content"])
      ]),
      _createElementVNode("div", null, [
        _createVNode($setup["ReadOnlyField"], {
          label: $setup.SYSTEM.company_registration_number,
          content: $props.companyInformation?.CompanyRegistrationNumber!
        }, null, 8, ["label", "content"])
      ]),
      ($props.isDisplayEmail)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode($setup["ReadOnlyField"], {
              label: $setup.SYSTEM.email,
              content: $props.companyInformation?.Country!
            }, null, 8, ["label", "content"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createVNode($setup["ReadOnlyField"], {
          label: $setup.SYSTEM.address,
          content: $props.companyInformation?.Address!
        }, null, 8, ["label", "content"])
      ]),
      _createElementVNode("div", null, [
        _createVNode($setup["ReadOnlyField"], {
          label: $setup.SYSTEM.phone_code,
          content: $props.companyInformation?.PhoneCode!
        }, null, 8, ["label", "content"])
      ]),
      _createElementVNode("div", null, [
        _createVNode($setup["ReadOnlyField"], {
          label: $setup.SYSTEM.contact_number,
          content: $props.companyInformation?.ContactNumber!
        }, null, 8, ["label", "content"])
      ])
    ])
  ]))
}