import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center text-sm p-2 gap-10" }
const _hoisted_2 = { class: "w-1/3 text-neutral-dark" }
const _hoisted_3 = { class: "w-2/3 text-neutral-black" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString($setup.props.label), 1),
    _createElementVNode("div", _hoisted_3, [
      (($setup.props.content?.length ?? 0) <= 50)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($setup.props.content?.length! > 0 ? $setup.props.content : $setup.SYSTEM.na), 1))
        : (_openBlock(), _createBlock(_component_el_popover, {
            key: 1,
            placement: "top",
            width: 400,
            trigger: "hover",
            content: $setup.props.content,
            "popper-class": "max-w-md max-h-30 whitespace-normal break-words"
          }, {
            reference: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString($setup.truncatedContent) + "...", 1)
            ]),
            _: 1
          }, 8, ["content"]))
    ])
  ]))
}