import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col lg:flex-row gap-2 pb-10 max-w-screen-xl w-full mx-auto" }
const _hoisted_2 = { class: "flex flex-col lg:w-2/3 gap-2" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col lg:w-1/3 gap-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["CompanyInformation"], {
        "company-information": $setup.tenantData,
        "is-display-email": false
      }, null, 8, ["company-information"]),
      _createVNode($setup["RequiredDocuments"], { "tenant-id": $props.tenantId }, null, 8, ["tenant-id"])
    ]),
    ($props.isShowStatusRemark)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode($setup["StatusCard"], { status: $setup.formStatus }, null, 8, ["status"]),
          _createVNode($setup["RemarkCard"], {
            remark: $setup.tenantData?.RemarkContent,
            status: $setup.formStatus,
            "processed-date": $setup.tenantData?.ProcessedDate,
            "ops-username": $setup.tenantData?.OpsUsername
          }, null, 8, ["remark", "status", "processed-date", "ops-username"])
        ]))
      : _createCommentVNode("", true)
  ]))
}