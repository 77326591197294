import { defineComponent as _defineComponent } from 'vue'
import StatusCard from "@/components/Common/StatusCard.vue";
import RemarkCard from "@/components/Common/RemarkCard.vue";
import TheClock from "@/components/TheClock.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheHeader from "@/components/TheHeader.vue";
import { ITenantRegistration } from "@/models/Tenant";
import { TenantAPI } from "@/services/tenant";
import { getAccessToken, getTenantId } from "@/utils/storage";
import { STATUS_TENANT } from "@ems/constants/account_registration_status";
import { DOCTYPE } from "@ems/constants/doc_type";
import CompanyAccountManagementModule from "@ems/containers/CompanyAccountManagement/CompanyAccountManagement.module";
import TenantRegistrationForm from "@ems/containers/Tenant/TheForm/TenantRegistration.Form.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";
import { onMounted, ref } from "vue";
import TenantUserModule from "@ems/containers/Tenant/TenantUser.module";
import { useRouter } from "vue-router";
import CompanyInformation from "@/components/Common/FormHelpper/CompanyInformation.vue";
import RequiredDocuments from "@/components/Common/FileHelper/RequiredDocuments.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'TenantDetail.Form',
  props: {
  tenantId: {
    type: String,
  },
  isShowStatusRemark: {
    type: Boolean,
    required: true,
  },
},
  emits: ["update:tenantData"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const router = useRouter();

const tenantData = ref<ITenantRegistration>();
const formStatus = ref();
const emit = __emit;

// const getIsLoadingDataCompany = computed(
//   () => CompanyAccountManagementModule.isLoadingDataCompanyGetter
// );

const fetchTenant = async (tenantId: string) => {
  await TenantUserModule.getTenantAccountRegistrationAction(tenantId ?? "");
  const dataRegistration = TenantUserModule.dataTenantRegistrationGetter;
  if (dataRegistration) {
    await CompanyAccountManagementModule.getFiles({
      TenantId: tenantId ?? "",
    });
    tenantData.value = {
      ...dataRegistration,
      CompanyRegistrationDocument: [],
      LetterOfAuthorization: [],
      OtherRelevantDocuments: [],
    } as ITenantRegistration;
    CompanyAccountManagementModule.getDataFilesGetter.map((file: any) => {
      const mappedFile = {
        name: file.Name,
        size: new Blob([file.Content]).size,
        status: "ready",
        raw: new Blob([file.Content], { type: file.Type }),
        uid: file.Id,
        url: file.Content,
      };
      switch (file.DocType) {
        case DOCTYPE[DOCTYPE.CompanyRegistrationDocument]:
          tenantData.value!.CompanyRegistrationDocument.push(mappedFile);
          break;
        case DOCTYPE[DOCTYPE.LetterOfAuthorization]:
          tenantData.value!.LetterOfAuthorization.push(mappedFile);
          break;
        case DOCTYPE[DOCTYPE.OtherRelevantDocuments]:
          tenantData.value!.OtherRelevantDocuments.push(mappedFile);
          break;
      }
    });
    formStatus.value = tenantData.value.Status!;

    emit("update:tenantData", tenantData.value);
  }
};

onMounted(async () => {
  await fetchTenant(props.tenantId ?? getTenantId()!);
});

const __returned__ = { props, router, tenantData, formStatus, emit, fetchTenant, StatusCard, RemarkCard, TheClock, TheFooter, TheHeader, get ITenantRegistration() { return ITenantRegistration }, get TenantAPI() { return TenantAPI }, get getAccessToken() { return getAccessToken }, get getTenantId() { return getTenantId }, get STATUS_TENANT() { return STATUS_TENANT }, get DOCTYPE() { return DOCTYPE }, get CompanyAccountManagementModule() { return CompanyAccountManagementModule }, TenantRegistrationForm, BaseSpinner, onMounted, ref, get TenantUserModule() { return TenantUserModule }, get useRouter() { return useRouter }, CompanyInformation, RequiredDocuments }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})