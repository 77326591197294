import { defineComponent as _defineComponent } from 'vue'
import { SYSTEM } from "@ems/locales/system";
import { computed } from "vue";
import { ElTooltip } from "element-plus";


export default /*@__PURE__*/_defineComponent({
  __name: 'ReadOnlyField',
  props: {
  label: String,
  content: String,
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const truncatedContent = computed(() =>
  props.content ? props.content.slice(0, 50) : SYSTEM.na
);

const __returned__ = { props, truncatedContent, get SYSTEM() { return SYSTEM }, computed, get ElTooltip() { return ElTooltip } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})