import { defineComponent as _defineComponent } from 'vue'
import ReadOnlyField from "./ReadOnlyField.vue";
import { SYSTEM } from "@ems/locales/system";


export default /*@__PURE__*/_defineComponent({
  __name: 'CompanyInformation',
  props: {
  companyInformation: {
    type: Object,
  },
  isDisplayEmail: {
    type: Boolean,
    default: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const __returned__ = { props, ReadOnlyField, get SYSTEM() { return SYSTEM } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})